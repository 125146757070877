<template>
  <b-card-code title="Companies" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group abel-cols="6" label-align="left" label-for="sortBySelect">
          <h5>Sort</h5>
          <b-input-group>
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
              <template #first>
                <option value="">none</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group v-if="$can('create', 'AddCompany')">
          <h5>Add</h5>
          <b-input-group>
            <b-button variant="primary" to="/companies/create" class="btn-icon">
              <feather-icon icon="PlusCircleIcon" /> New Company
            </b-button>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table striped hover responsive class="position-relative" :per-page="perPage"
      :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">
      <template #cell(coInSub)="data">
        <b-button v-if="!data.value && $can('edit', 'EditCompany')" variant="flat-primary" class="btn-icon" size="sm" :to="{ name: 'edit-company', params: { id: data.item.pkid_accreditation_company } }" >
          <feather-icon icon="Edit2Icon" size="20" />
        </b-button>
        {{ data.value }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group abel-cols="6" label-align="left" label-for="sortBySelect">
          <h5>Per Page</h5>
        <b-form-select id="perPageSelect" v-model="perPage" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody } from "bootstrap-vue";
import axios from "@/libs/axios";

export default {
  components: {
    BCardCode,

    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {},
  data() {
    return {
      perPage: 50,
      pageOptions: [50, 100, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        { key: "pkid_accreditation_company", label: "Id", sortable: true },
        { key: "Name", label: "Name", sortable: true },
        { key: "Description", label: "Description", sortable: true },
        { key: "coInSub", label: "coInSub", sortable: true },
      ],
      items: [],
    };
  },
  async created() {
    await axios
      .get("/company")
      .then((results) => {
        this.items = results.data;
        this.totalRows = this.items.length;
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });
  },
  mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
};
</script>
<style>
</style>